// IMPORTANT: Prevent importing as much code here as possible.
// The sooner the browser can load this file, the sooner we show loading state

import './styles/globals.css';

import {
  setDocumentDataAttribute,
  setDocumentProperty,
} from '@/ui/utils/document';

// TODO: Consume from `@passkeys/ui`.
const black = '#181818';

if (__STANDALONE_MODE__) {
  addEventListener('DOMContentLoaded', () => {
    const loadingStateElements = document.querySelectorAll(
      '.__wallet-loading-state__',
    );
    for (const element of loadingStateElements) {
      element.remove();
    }
  });
}

function calculateColors({ variant, colors }) {
  const collapsedBackgroundColorFallback =
    variant === 'dark' ? black : undefined;
  const collapsedBackgroundColor =
    colors?.collapsed?.backgroundColor ?? collapsedBackgroundColorFallback;

  return {
    collapsedBackgroundColor,
  };
}

function setLoadingTheme({ variant, colors }) {
  const { collapsedBackgroundColor } = calculateColors({ variant, colors });

  setDocumentDataAttribute('theme', variant);
  setDocumentProperty('--loading-collapsed-bg-color', collapsedBackgroundColor);
}

try {
  const theme = new URLSearchParams(location.search).get('theme');
  if (theme) {
    const { colors, variant } = JSON.parse(theme);
    setLoadingTheme({ variant, colors });
  }
} catch (error) {
  console.error('Failed to set theme properties', error);
}

// Dynamically import app so loading state renders as soon as possible
import('./main');
